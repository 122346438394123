import { createApp } from 'vue';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import Menubar from 'primevue/menubar';
import Datatable from 'primevue/datatable';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import axios from 'axios';
import VueAxios from 'vue-axios';

import router from './router';

const app = createApp(App);

app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(VueAxios, axios);

app.component('Menubar', Menubar);
app.component('Datatable', Datatable);
app.component('Column', Column);
app.component('Toast', Toast);

app.mount('#app');
