<template>
  <div>
    <Menubar :model="items" />
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        items: [
          {
            label: 'EC',
            icon: 'pi pi-fw pi-calendar-plus',
            to: '/ec',
          },
          {
            label: 'Hose',
            icon: 'pi pi-fw pi-pencil',
            to: '/hose',
          },
        ],
      };
    },
  };
</script>

<style></style>
