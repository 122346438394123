import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/ec',
    name: 'EC',
    component: () => import('../views/EC.vue')
  },
  {
    path: '/hose',
    name: 'Hose',
    component: () => import('../views/Hose.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
